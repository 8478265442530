import { render, staticRenderFns } from "./index_txjl.vue?vue&type=template&id=2658c2a2&scoped=true"
import script from "./index_txjl.vue?vue&type=script&lang=js"
export * from "./index_txjl.vue?vue&type=script&lang=js"
import style0 from "./assets/index.rem.css?vue&type=style&index=0&id=2658c2a2&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2658c2a2",
  null
  
)

export default component.exports